import cntl from "cntl";
import Link from "next/link";
import { Locked } from "~/components/assets/icons/locked";
import { useUser } from "~/components/hooks/useUser";
import { BetaTag } from "./BetaTag";
import { NewTag } from "./NewTag";
export interface NavItemType {
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  premiumOnly: boolean;
  partnerAccess: boolean;
  beta: boolean;
  new: boolean;
}

export const NavItem: React.FC<{
  item: NavItemType;
  currentNavItem: NavItemType | undefined;
}> = ({ item, currentNavItem }) => {
  const { data: user } = useUser();
  const userIsPremium = user?.isMember ?? false;
  const doesNotHavePremiumAccess = item.premiumOnly && !userIsPremium;

  const userHasPartner = user?.partners?.includes("MO_CRCL");
  const doesNotHavePartnerAccess = !(item.partnerAccess && userHasPartner);

  const showLock = doesNotHavePremiumAccess && doesNotHavePartnerAccess;

  const isCurrentNavItem = item === currentNavItem;

  return (
    <Link key={item.name} href={item.href} passHref>
      <div
        className={cntl`
        group
        flex
        cursor-pointer
        items-center
        justify-between
        rounded-md
        px-2
        py-2
        font-medium
        hover:bg-lightTeal
        ${isCurrentNavItem && "bg-lightTeal"}
      `}
      >
        <div
          className={cntl`
          ${
            isCurrentNavItem
              ? "text-deepTeal"
              : "text-gray-600 group-hover:text-deepTeal"
          }
          flex
          items-center

          text-sm

        `}
        >
          <item.icon
            className={cntl`
                ${
                  item.name === currentNavItem?.name
                    ? "bg-lightTeal text-deepTeal"
                    : " text-gray-400 "
                }
              mr-3 h-6 w-6 flex-shrink-0 group-hover:text-deepTeal
              `}
            aria-hidden="true"
          />
          {item.name}
        </div>
        {item.beta && <BetaTag />}
        {showLock && (
          <Locked className="h-5 w-5 fill-current text-disabledGrey" />
        )}
        {item.new && <NewTag />}
      </div>
    </Link>
  );
};
