import React from "react";

const EntityPickerSkeleton: React.FC = () => {
  return (
    <div
      className={"animate-puls ml-3 flex cursor-wait items-center space-x-2"}
    >
      <div className="h-6 w-6 rounded-full bg-gray-300" />
      <div className="flex flex-col space-y-1">
        <div className="my-1 h-4 w-32 rounded-full bg-gray-300"></div>
        <div className="h-4 w-12 rounded-full bg-gray-300"></div>
      </div>
    </div>
  );
};

export default EntityPickerSkeleton;
