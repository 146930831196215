import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { ComponentType, FC, useEffect } from "react";
import { useUser } from "../../hooks/useUser";
import LoadingView from "../../views/LoadingView";

function withOnboardingRequired<P extends {}>(
  WrappedComponent: ComponentType<any>
): FC<P> {
  const WithPremiumRequired: FC<P> = (props) => {
    const router = useRouter();
    const { data: user, isLoading, isSuccess, isFetching } = useUser();

    const isPreparing = isLoading || !user?.firstName;
    const missingTrust = isSuccess && !isFetching && !user?.agreedToTos;
    const missingProfile = isSuccess && !isFetching && !user?.firstName;
    const usingFsaModuleFlow = Cookies.get("flow") === "fsaModule";

    useEffect(() => {
      if (!usingFsaModuleFlow) {
        if (missingTrust) {
          router.push("/trust");
        } else if (missingProfile) {
          router.push("/profile/onboard");
        }
      }
    }, [missingProfile, missingTrust, router, usingFsaModuleFlow]);

    if (isPreparing)
      return <LoadingView loadingText="Loading your profile..." />;

    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  };

  return WithPremiumRequired;
}

export default withOnboardingRequired;
