import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { Entity } from "~/components/hooks/useOperation";
import { DataResponse } from "~/lib/core/DataResponse";

export const CURRENT_ENTITY_QUERY_KEY = "currentEntity";

export function useGetSelectedEntity() {
  return useQuery<Entity | null, AxiosError>(
    [CURRENT_ENTITY_QUERY_KEY],
    async () => {
      return (
        await axios.get<DataResponse<Entity | null>>(
          "/api/proxy/v1/entity/selected"
        )
      ).data?.data;
    }
  );
}
