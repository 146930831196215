import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";

export interface Operation {
  id: string;
  name: string;
  entities?: Entity[] | null;
}

export type RevenueRangeValues =
  | "no-sales"
  | "1-50K"
  | "50-100K"
  | "100-300K"
  | "300-500K"
  | "500-700K"
  | "700-900K"
  | "900K-1MM"
  | "1-1.5MM"
  | "1.5MM+";

export interface Entity {
  id: string;
  name: string;
  type: string | null;
  annualGrossRevenue?: number;
  annualGrossRevenueRange?: RevenueRangeValues;
  dateOfFormation?: string;
  taxId?: string;
  hasExistingFsaRecords: boolean;
  selectedAt: string | null;
}

export const OPERATION_QUERY_KEY = "operation";

export function useOperation() {
  return useQuery<Operation | undefined, AxiosError>(
    OPERATION_QUERY_KEY,
    async () => {
      try {
        const response = await axios.get<Operation>("/api/proxy/v1/operation");

        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const isRecoverableError =
            error.response?.status === 401 || error.response?.status === 404;

          if (isRecoverableError) return undefined;

          throw error;
        } else {
          throw error;
        }
      }
    },
    {
      retry: false,
      // Since almost everything in our app relies on the operation being present,
      // go to error boundary if we get any error code other than a 404
      useErrorBoundary: (error) => error?.response?.status !== 404,
    }
  );
}
