import axios, { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Entity } from "~/components/hooks/useOperation";
import { CURRENT_ENTITY_QUERY_KEY } from "./useSelectedEntity";

export const SET_SELECTED_ENTITY_MUTATION_KEY = "setSelectedEntity";

type selectedEntityStateType = Entity | null | undefined;

export function useSetSelectedEntity() {
  const queryClient = useQueryClient();

  return useMutation<Entity, AxiosError, Entity, selectedEntityStateType>(
    [SET_SELECTED_ENTITY_MUTATION_KEY],
    async ({ id }) => {
      const resp = await axios.put<Entity>(
        `/api/proxy/v1/entity/selected`,
        {
          entityId: id,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return resp.data;
    },
    {
      // Optimistically update setting current Entity for a responsive UI
      onMutate: async (currentEntity) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(CURRENT_ENTITY_QUERY_KEY);

        // Save the old data incase shit hits the fan
        const previousEntity = queryClient.getQueryData<
          Entity | null | undefined
        >(CURRENT_ENTITY_QUERY_KEY);

        // Optimistically update with new selected Entity state
        queryClient.setQueryData<selectedEntityStateType>(
          CURRENT_ENTITY_QUERY_KEY,
          () => currentEntity
        );

        return previousEntity;
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (_err, _newEntity, context) => {
        queryClient.setQueryData<selectedEntityStateType>(
          CURRENT_ENTITY_QUERY_KEY,
          context
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(CURRENT_ENTITY_QUERY_KEY);
      },
    }
  );
}
