/* This example requires Tailwind CSS v2.0+ */
import { Plus } from "~/components/assets/icons/plus";
import { Entity, useOperation } from "~/components/hooks/useOperation";
import { useSetSelectedEntity } from "~/components/hooks/useSetSelectedEntity";
import WithLeftIcon from "../../common/WithLeftIcon";
import LinkButton from "../../common/buttons/LinkButton";
import { useGetSelectedEntity } from "../../hooks/useSelectedEntity";
import EntityPickerDisplay from "./EntityPickerDisplay";
import EntityPickerSkeleton from "./EntityPickerSkeleton";

export const EntityPicker: React.FC = () => {
  const selectedEntity = useGetSelectedEntity();
  const setSelectedEntity = useSetSelectedEntity();

  const operation = useOperation();

  if ((selectedEntity.isLoading || operation.isLoading) && !operation.data) {
    return <EntityPickerSkeleton />;
  }

  if (!operation.data) {
    <div className="mt-4 px-3">
      <LinkButton
        variant="Primary"
        color="petrolGreen"
        href="/operation/create"
      >
        <WithLeftIcon
          icon={<Plus className="mr-2 h-5 w-5 fill-current text-white" />}
        >
          Create your Operation
        </WithLeftIcon>
      </LinkButton>
    </div>;
  }

  if (
    operation.data?.entities &&
    operation.data.entities.length > 0 &&
    selectedEntity.data
  ) {
    return (
      <EntityPickerDisplay
        currentEntity={selectedEntity.data}
        allEntities={operation.data.entities}
        isLoading={false}
        setCurrentEntity={(entity: Entity) => {
          // TODO: analytics
          setSelectedEntity.mutate(entity);
        }}
      />
    );
  }

  return (
    <div className="mt-4 px-3">
      <LinkButton variant="Primary" color="petrolGreen" href="/entity/create">
        <WithLeftIcon
          icon={<Plus className="mr-2 h-5 w-5 fill-current text-white" />}
        >
          Create your first Farm/Ranch
        </WithLeftIcon>
      </LinkButton>
    </div>
  );
};
