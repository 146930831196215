import { Popover, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import cntl from "cntl";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { AddCircle } from "~/components/assets/icons/add-circle";
import { Storefront } from "~/components/assets/icons/storefront";
import { Entity } from "~/components/hooks/useOperation";
import WithLeftIcon from "../../common/WithLeftIcon";

interface EntityPickerDisplayProps {
  currentEntity: Entity;
  allEntities: Entity[];
  setCurrentEntity: (entity: Entity) => void;
  isLoading: boolean;
}

const EntityPickerDisplay: React.FC<EntityPickerDisplayProps> = ({
  currentEntity,
  allEntities,
  setCurrentEntity,
  isLoading,
}) => {
  const router = useRouter();

  const isSelected = (entity: Entity) =>
    currentEntity && currentEntity.id === entity.id;

  return (
    <Popover>
      {({ open }) => (
        <>
          <div className="relative">
            <Popover.Button
              className={cntl`
                sm:text-sm"
                relative
                h-20
                w-full
                cursor-pointer
                border-b
                border-borderGrey
                bg-lighterGrey
                py-4
                pl-3
                pr-10
                text-left
                hover:bg-lightGrey
                focus:border-darkGreen
                focus:outline-none
                focus:ring-1
                focus:ring-darkGreen
            `}
            >
              <div
                className={`flex items-center space-x-2 ${
                  isLoading && "animate-pulse"
                }`}
              >
                {isLoading ? (
                  <div className="h-6 w-6 rounded-full bg-gray-300" />
                ) : (
                  <Storefront className="h-6 w-6" />
                )}

                <div className="flex flex-col space-y-0.5">
                  {isLoading ? (
                    <>
                      <div className="my-1 h-4 w-32 rounded-full bg-gray-300"></div>
                      <div className="h-4 w-12 rounded-full bg-gray-300"></div>
                    </>
                  ) : (
                    <>
                      <span
                        className={cntl`
                    text-base
                    font-semibold
                    text-darkBlue
                    ${isLoading ? "rounded-lg bg-gray-500" : ""}
                  `}
                      >
                        {currentEntity.name}
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          router.push(`/entity/${currentEntity.id}/edit`);
                        }}
                        className="text-xs underline hover:text-darkGreen"
                      >
                        Edit Farm/Ranch
                      </span>
                    </>
                  )}
                </div>
              </div>
              {!isLoading && (
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              )}
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel
                className={cntl`
                  absolute
                  z-10
                  mt-2
                  max-h-60
                  w-full
                  overflow-auto
                  rounded-md
                  border
                  border-disabledGrey
                  bg-white
                  p-2
                  text-base
                  shadow-lg
                  ring-1
                  ring-black
                  ring-opacity-5
                  focus:outline-none
                  sm:text-sm`}
              >
                <div className="space-y-2">
                  <div className="uppercase text-darkGrey">
                    My Farms/Ranches
                  </div>
                  <div>
                    {allEntities.map((entity) => {
                      const selected = isSelected(entity);
                      return (
                        <div
                          key={entity.id}
                          onClick={() => setCurrentEntity(entity)}
                          className={cntl`
                            relative
                            cursor-pointer
                            select-none
                            list-none
                            rounded-md
                            py-2
                            pl-8
                            pr-4
                            hover:bg-lightGreen
                            hover:text-darkGreen
                      `}
                        >
                          <>
                            <span
                              className={cntl`
                              ${selected ? "font-semibold" : "font-normal"}
                              block
                              truncate
                            `}
                            >
                              {entity.name}
                            </span>

                            {selected ? (
                              <span
                                className={cntl`
                              absolute inset-y-0 left-0 flex items-center pl-1.5
                            `}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="-mx-2 mt-1 border-t pt-1">
                  <div className="mx-2">
                    <button
                      onClick={() => router.push("/entity/create")}
                      className="flex w-full rounded-md p-2 text-darkGreen hover:bg-lightGreen"
                    >
                      <WithLeftIcon
                        icon={
                          <AddCircle className="mr-2 h-4 w-4 fill-current" />
                        }
                      >
                        Add a new Farm/Ranch
                      </WithLeftIcon>
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

export default EntityPickerDisplay;
